import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule, Location } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-filters-chips',
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './filters-chips.component.html',
  styleUrl: './filters-chips.component.scss'
})
export class FiltersChipsComponent implements OnInit, OnDestroy {
  filterValues!: string[];
  filterKeys!: string[];
  activedRoute = inject(ActivatedRoute);
  router = inject(Router);
  location = inject(Location);
  changes = inject(ChangeDetectorRef);
  subs: Subscription[] = [];

  ngOnInit(): void {
    this.initChips()
  }

  initChips() {
    const sub = this.activedRoute.queryParams.subscribe((params) => {
      this.filterValues = Object.values(params)
      this.filterKeys = Object.keys(params)
      this.changes.detectChanges();
    });
    this.subs.push(sub);
  }
  get showClearAll(): boolean {
    return this.filterKeys.length > 0;
  }
  removeFilter(key: string) {
      const queryParams = this.activedRoute.snapshot.queryParams;
      const newUrl = this.location.path().replace(`${key}=${queryParams[key]}`,'');
      this.changes.detectChanges();
      this.router.navigateByUrl(newUrl);

  }
  removeFilterAll() {
    this.router.navigateByUrl(this.location.path().split('?')[0]);
    this.changes.detectChanges();
  }
  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

}
