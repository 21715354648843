@if (formType === 'number') {}
@if (formType === 'text') {
  <div class="mb-5">
    <app-input-label labelFor="first-name" labelClass="label--bottom-margin">{{
      label
    }}</app-input-label>
    <app-input [formControl]="formControl"></app-input>
  </div>
}
@if (formType === 'date') {
<div class="mb-5">
  <app-input-label labelFor="permitIssueDate" labelClass="label--bottom-margin">{{ label }}</app-input-label>

  <input type="date" [formControl]="formControl"  class="block border-0 focus:ring-2 focus:ring-inset focus:ring-mango placeholder:text-mango-lightGray ring-1 ring-gray-300 ring-inset rounded-lg shadow-sm sm:leading-6 w-full"/>

</div>
}
@if (formType === 'dropdown') {
  <div class="mb-5">
    <app-input-label labelFor="title" labelClass="label--bottom-margin">{{
      label
    }}</app-input-label>
    <select name="title" required base-select [formControl]="formControl">
      <option value="" disabled selected>{{ label }}</option>
      @for (item of dropdown; track $index + item.key) {
        <option [value]="item.key">
          {{ item.value }}
        </option>
      }
    </select>
  </div>
}
