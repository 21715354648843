@for (filter of filterKeys; track $index) {
  <button
    (click)="removeFilter(filter)"
    type="button"
    style="
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    "
    class="inline-flex rounded-lg bg-mango-lightOrange px-4 py-[0.62rem] text-mango focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mango"
  >
    <span>{{ filterValues[$index]  | titlecase }}</span>
    <img src="assets/images/svgs/x-close--btn.svg" alt="" class="h-5 w-5" />
  </button>
}
 
@if (showClearAll) {
    <button
        (click)="removeFilterAll()"
        type="button"
        style="
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05)
        "
        class="inline-flex rounded-lg bg-white px-4 py-[0.62rem] hover:outlline-red-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
    >
        <img src="assets/images/svgs/trash.svg" alt="" class="h-5 w-5" />
        <span>Clear All</span>
    </button>
}
