import { Component, inject, input, Input, OnDestroy, OnInit } from '@angular/core';
import { InputLabelComponent } from "../input-label/input-label.component";
import { CommonModule, Location } from '@angular/common';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-filter-dropdown-item',
  standalone: true,
  imports: [
    BaseSelectDirective,
    InputLabelComponent,
    CommonModule,
    ReactiveFormsModule
  ],
  templateUrl: './filter-dropdown-item.component.html',
  styleUrl: './filter-dropdown-item.component.scss'
})
export class FilterDropdownItemComponent implements OnInit, OnDestroy {

  @Input({ required: true }) label!: string;
  @Input({ required: true }) searchKey!: string;
  @Input({ required: true }) formType!: 'text' | 'number' | 'date' | 'dropdown';
  @Input() dropdown!: { key: string; value: string }[] | undefined;

  activatedRoute = inject(ActivatedRoute);
  router = inject(Router);
  subs: Subscription[] = [];
  location = inject(Location);

  formControl = new FormControl('');

  watchForQueryParamChanges() {

    const sub = this.activatedRoute.queryParams.subscribe((params) => {
      if (params[this.searchKey]) {
        this.formControl.setValue(params[this.searchKey]);
      }
    });
    this.subs.push(sub);
  }

  startWatchingForFilterChange() {
    const sub = this.formControl.valueChanges.subscribe((value) => {
      const queryParams = this.location.path().split('?')[1] || '';
      const params = new URLSearchParams(queryParams);
      params.set(this.searchKey, value??'');
      let newUrlParams = "";
       params.forEach((value, key) => newUrlParams +=`${key}=${value}&`);
      this.router.navigateByUrl(
        `${this.location.path(false).split('?')[0]}${value ? '?' + newUrlParams : ''}`
      );
    });
    this.subs.push(sub);
  }
  ngOnInit(): void {
    this.watchForQueryParamChanges();
    this.startWatchingForFilterChange();
  }
  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

}
