<!-- Search Input and Filters -->
<div class="mt-5 flex justify-start gap-5 align-middle">
  <div class="relative">
    <!-- <div
      class="pointer-events-none inset-y-0 left-0 mb-[-45px] flex items-center pl-[0.88rem]"
    > -->
    <img
      class="absolute bottom-[13px] left-3.5"
      src="assets/images/svgs/search.svg"
      alt=""
    />

    <input
      type="search"
      name="search"
      [formControl]="searchControl"
      class="block w-full rounded-lg border-0 py-[0.62rem] pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-base placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mango focus-visible:ring-mango sm:text-sm sm:leading-6 xl:min-w-[400px]"
      placeholder="Search"
    />
    <!-- </div> -->
  </div>
  <!-- <div class="inline-flex gap-5"> -->
  <app-filters-chips class="inline-flex gap-5"></app-filters-chips>
  <!-- </div> -->
  <button
    #trigger
    (click)="showFilters = !showFilters"
    type="button"
    class="flex gap-2 rounded-lg border border-solid border-gray-300 bg-white px-4 py-[0.62rem] shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mango"
    id="menu-button"
    aria-expanded="true"
    aria-haspopup="true"
  >
    <img src="assets/images/svgs/filter-lines.svg" alt="" class="h-5 w-5" />
    <span>Filters</span>
  </button>
  <!-- <app-filter-dropdown> -->
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="showFilters"
    cdkConnectedOverlayFlexibleDimensions="true"
    (overlayOutsideClick)="showFilters = false"
  >
    <div class="p-5 rounded-xl bg-white shadow-xl w-80" role="none">
      @if (filters) {
        @for (filter of filters; track $index) {
          <app-filter-dropdown-item
            [label]="filter.label"
            [formType]="filter.type"
            [searchKey]="filter.filterKey"
            [dropdown]="filter.dropdown"
          >
          </app-filter-dropdown-item>
        }
      }
    </div>
  </ng-template>
  <!-- </app-filter-dropdown> -->
</div>
