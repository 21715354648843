import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { InputLabelComponent } from '../input-label/input-label.component';
import { debounceTime, distinctUntilChanged, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule, Location } from '@angular/common';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import { InputDateDirective } from '@shared/directives/input-date.directive';
import { ButtonDirective } from '@shared/directives/button.directive';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { dropdownMenuAnimation } from '@shared/animations/animations';
import { FiltersChipsComponent } from "../filters-chips/filters-chips.component";
import { FilterDropdownItemComponent } from "../filter-dropdown-item/filter-dropdown-item.component";
import { IFILTER } from 'app/app.constants';
import { OverlayModule } from '@angular/cdk/overlay';

@Component({
  selector: 'app-filters',
  standalone: true,
  templateUrl: './filters.component.html',
  styleUrl: './filters.component.scss',
  imports: [
    InputLabelComponent,
    CommonModule,
    BaseSelectDirective,
    InputDateDirective,
    ButtonDirective,
    FiltersChipsComponent,
    FilterDropdownItemComponent,
    ReactiveFormsModule,
    OverlayModule
  ],
  animations: [dropdownMenuAnimation],
})
export class FiltersComponent implements OnInit, OnDestroy {
  @Input({ required: true }) filters: IFILTER[] = [];

  location = inject(Location);
  activatedRoute = inject(ActivatedRoute);
  router = inject(Router);
  notify: any;
  searchControl = new FormControl(null);
  change = inject(ChangeDetectorRef);
  subs: Subscription[] = [];
  showFilters: boolean = false;
  ngOnInit(): void {
    this.onSearchChange();
  }

  onSearchChange() {
    const sub = this.searchControl.valueChanges
      .pipe(
        debounceTime(500), // Wait for 300ms of inactivity
        distinctUntilChanged() // Only emit if the current value is different from the last
      )
      .subscribe({
        next: (value) => {
          if (value != null) {
            // Get the current URL query parameters
            const url = this.location.path(false);
            const [path, queryString] = url.split('?');
            const searchParams = new URLSearchParams(queryString || '');
  
            // Update or add the 'search' query parameter
            if (value == '') {
              searchParams.delete('search');
            } else {
              searchParams.set('search', value);
            }
  
            // Navigate to the new URL with the updated query params
            const newUrl = `${path}?${searchParams.toString()}`;
            this.router.navigateByUrl(newUrl);
          }
        },
      });
    this.subs.push(sub);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}